import axios from "axios";
const axiosInstance = axios.create({ baseURL: process.env.React_APP_API_URL });
const siteId = process.env.React_APP_SITE_ID;
const destinationCountryId = process.env.React_APP_DESTINATION_COUNTRY_ID;

export const getCountryList = async() =>  {
  try {
    const {data,status} = await axiosInstance.get("/country/basic");
    return {data,status};
  } catch (error) {
    
  }
};

export const getCountryListWithoutTargetExclusion = async() =>  {
  try {
    const {data,status} = await axiosInstance.get("/country/basic?excludeTargetCountry=false");
    return {data,status};
  } catch (error) {

  }
};

export const getCountryListSearch = async(val) =>  {
  try {
    const {data,status} = await axiosInstance.get(`/country/basic?name=${val}`);
    return {data,status};
  } catch (error) {
      
  }
};



export const getSiteInfo = async() =>  {
  try {
    const {data,status} = await axiosInstance.get(`site-info/${siteId}`);
    return {data,status};
  } catch (error) {

  }
};

export const updateVisitorCount = async() =>  {
  try {
    const {data,status} = await axiosInstance.put(`site-info/visitor-count/${siteId}`);
    return {data,status};
  } catch (error) {

  }
};



export const getEducationList = async() =>  {
  try {
    const {data,status} = await axiosInstance.get("education");
    return {data,status};
  } catch (error) {

  }
};
export const getProfessiomList = async() =>  {
  try {
    const {data,status} = await axiosInstance.get("profession");
    return {data,status};
  } catch (error) {

  }
};
export const getPurposeOfVisit = async() =>  {
  try {
    const {data,status} = await axiosInstance.get("purpose-of-visit");
    return {data,status};
  } catch (error) {

  }
};


export const getVisaType = async(url) =>  {
  try {
    const {data,status} = await axiosInstance.get(url ? url :`visaVariant/0/${destinationCountryId}?siteId=${siteId}&fetchImages=false&types=all`);
    return {data,status};
  } catch (error) {
    return {status:error.response?.status,data:""}
  }
};

export const getWhyChooseUs = async() =>  {
  try {
    const {data,status} = await axiosInstance.get(`site-why-choose-us/site/${siteId}`);
    return {data,status};
  } catch (error) {

  }
};

export const getThings = async() =>  {
  try {
    const {data,status} = await axiosInstance.get(`country/${destinationCountryId}`);
    return {data,status};
  } catch (error) {

  }
};

export const getReviews = async(limit) =>  {
  try {
    const {data,status} = await axiosInstance.get(limit ? limit :`reviews/site/${siteId}`);
    return {data,status};
  } catch (error) {

  }
};
export const getFAQ = async(limit) =>  {
  try {
    const {data,status} = await axiosInstance.get(`site-faq/site/${siteId}`+(limit ? limit : ""));
    return {data,status};
  } catch (error) {

  }
};
export const getBlogs = async(limit) =>  {
  try {
    const {data,status} = await axiosInstance.get(`blog/basic/${siteId}`+(limit ? limit : ""));
    return {data,status};
  } catch (error) {

  }
};
export const getBlogDetail = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`blog/${siteId}/${id}`);
    return {data,status};
  } catch (error) {

  }
};

export const getLightApplicationDetail = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`applicant/basic?applicationDisplayId=${id}&siteId=${siteId}`);
    return {data,status};
  } catch (error) {

  }
};

export const getApplicationDetail = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`applicant?applicationDisplayId=${id}&siteId=${siteId}`);
    return {data,status};
  } catch (error) {

  }
};

export const getApplicantDetail = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`applicant/${id}?siteId=${siteId}`);
    return {data,status};
  } catch (error) {

  }
};

export const createApplication = async(appData) =>  {
  try {
    const {data,status} = await axiosInstance.post(`applicant`,appData);
    return {data,status};
  } catch (error) {

  }
};

export const postContactUs = async(contactData) =>  {
  try {
    const {data,status} = await axiosInstance.post(`query`,contactData);
    return {data,status};
  } catch (error) {

  }
};

export const updatePaymentInfo = async(paymentData) =>  {
  try {
    const {data,status} = await axiosInstance.post(`payment/payment-info`,paymentData);
    return {data,status};
  } catch (error) {

  }
};
export const updateApplicantDetails = async(id,updateData) =>  {
  try {
    const {data,status} = await axiosInstance.put(`applicant/${id}`,updateData);
    return {data,status};
  } catch (error) {

  }
};



export const uploadDocument = async(id,docType,formData) =>  {
  try {
    const {data,status} = await axiosInstance.post(`document/${id}/${docType}/upload`,formData);
    return {data,status};
  } catch (error) {

  }
};


export const getDocuments = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`document?applicantId=${id}`);
    return {data,status};
  } catch (error) {

  }
};
export const getVisaServiceTypes = async(id) =>  {
  try {
    const {data,status} = await axiosInstance.get(`service-type/${id}`);
    return {data,status};
  } catch (error) {

  }
};

export const getFess = async(visaVariantId,citizenshipCountryId) =>  {
  try {
    const {data,status} = await axiosInstance.get(`visaVariant/${visaVariantId}/${citizenshipCountryId}/${destinationCountryId}/fee`);
    return {data,status};
  } catch (error) {

  }
};

export const getPaymentLink = async(paymentMethod,orderData) =>  {
  try {
    const {data,status} = await axiosInstance.post(`payment/${paymentMethod}/order`,orderData,{
      headers: {
          "Content-Type": "application/json"
      }
  });
    return {data,status};
  } catch (error) {

  }
};



