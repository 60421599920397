import React, { useEffect, useState } from 'react';
import { Link , useLocation } from "react-router-dom";
import { getSiteInfo } from '../../action/visa';


const Header = () => {

    const location = useLocation();

    //const ScrollListenerComponent = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const toggleClass = 'main_menu page-header is-sticky'; // replace 'yourToggleClass' with the actuayou want to toggle
    
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.pageYOffset);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        desktopToMobile('mob_notify', 'desktop_menu');
    }, []);

    async function openNav(e) {
		e.preventDefault()
		const mySidenav = document.getElementById("mySidenav");
        const main = document.getElementById("main");
        if(mySidenav){
            mySidenav.style.width = '100%';
        }
        if(main){
            main.style.marginLeft = '100%';
        }
	}

    async function closeNav(e) {
		e.preventDefault()
        const mySidenav = document.getElementById("mySidenav");
        const main = document.getElementById("main");
        if(mySidenav){
            mySidenav.style.width = '0';
        }
        if(main){
            main.style.marginLeft = '0';
        }
        
	}

    
  
    function desktopToMobile(mobileId, desktopId){
        document.getElementById(mobileId).innerHTML = document.getElementById(desktopId).innerHTML
    }

    const [siteInfo, setSiteInfo] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const res = await getSiteInfo();
        if(res?.status === 200){
            setSiteInfo(res.data);
        }
    }

    
      
  return (
    
        

    
    <>

    {/* 
<div className={headerPosition == true ? 'home_pg' : ''}>
*/}
<div className=''>

    <header className={scrollPosition > 150 ? toggleClass : '' +"main_menu page-header"} >
        <div className="container">
            <div className="row">

                <div className="col-md-3">
                    <div className="logo_togle">
                        <div className="logo">
                            <Link to="/">
                                <img src="/img/logo.svg"  alt='logo' loading="lazy" className='header-logo'/>
                            </Link>
                        </div>

                        <div className="menu_togle_btn">
                            <span onClick={e => openNav(e)}>
                                <i className="fa fa-bars" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>

                </div>

                <div className="col-md-9">
                    <div className="head_menus">
                        <nav className="" id="desktop_menu">
                            <ul>
                                <li className={location.pathname === '/' ? 'active' : ''}>
                                    <Link to="/">
                                
                                        <span>Home </span></Link></li>

                                <li className={location.pathname === '/how-to-apply-dubai-e-visa-online' ? 'active' : ''}><Link to="/how-to-apply-dubai-e-visa-online">
                        
                                        <span>How To Apply</span></Link></li>

                                <li className={location.pathname === '/dubai-e-visa-online-types' ? 'active' : ''}><Link to="/dubai-e-visa-online-types">
                                       
                                        <span>Dubai Visa Types</span></Link>
                                

                                </li>

                                <li className={location.pathname === '/track-visa-application' ? 'active' : ''}><Link to="/track-visa-application">
                                       
                                        <span>Track Visa Status</span></Link></li>

                                <li className={location.pathname === '/dubai-e-visa-online-application-form' ? 'active' : ''}><Link to="/dubai-e-visa-online-application-form">
                                      
                                        <span>Urgent Visa</span></Link></li>

                                <li className={location.pathname === '/contact' ? 'active' : ''}><Link to="/contact">
                                        
                                        <span>Contact Us</span></Link></li>
                            </ul>
                            <div className="head_whatsapp">
                                <a target='_blank' href={"https://api.whatsapp.com/send?phone="+siteInfo.whatsAppNumber+"&text=Hello"}
                                    data-toggle="tooltip" data-placement="top" title={siteInfo.whatsAppNumber}> <img
                                        src="/img/vector.avif" alt='expressvisa' loading="lazy"/>
                                    <span>Get Visa in 3-5 hours</span> </a>
                            </div>
                        </nav>

                    </div>
                </div>


            </div>
        </div>
    </header>


    <div id="mySidenav" className="sidenav">
        <div className="sidenavg">
            <div className="logo">
                <Link to="/">
                    <img src="/img/logo.svg" alt='sedelogo' loading="lazy" height={50}/>
                </Link>
            </div>
            <div className="mobile_menu" id="mob_notify">

            </div>
            <a href="#" className="closebtn" onClick={e => closeNav(e)} >×</a>

        </div>
    </div>

</div>

    </>
  )
};

export default Header;